import React, { useEffect, useRef, useState } from 'react';
import './Select.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchList } from '../List/list.slice';
import { host } from '../../App';
import addIcon from '../../images/modal-plus.png';
import closeIcon from '../../images/close.png';
import { setElement } from '../Element/element.slice';

function Select({ property, props, data, action, setElementState }) {
    const dispatch = useDispatch();
    const [listShowed, setListShowed] = useState(false);
    useEffect(() => {
        if (listShowed) {
            const listItems = document.getElementsByClassName('select-absolute-list-item');
            if (listItems[0]) {
                listItems[0].focus();
            }
        }
    }, [listShowed]);
    const [selectAbsoluteListShowed, setSelectAbsoluteListShowed] = useState(false);
    const [filteredItems, setFilteredItems] = useState([]);

    const onInputChange = (e) => {
        if (e.target.value === '') {
            setFilteredItems([]);
            setSelectAbsoluteListShowed(false);
            return;
        }
        let results = items.filter(item => item[pageData.listTitle]?.toString().trim().toLowerCase().startsWith(e.target.value.trim().toLowerCase()));
        results = results.concat(items.filter(item => item[pageData.listTitle]?.toString().trim().toLowerCase().includes(e.target.value.trim().toLowerCase()) && !results.find(rItem => rItem[pageData.listTitle] === item[pageData.listTitle])));
        setFilteredItems(results);
        setSelectAbsoluteListShowed(true);
    }

    const { items, status, pageData } = useSelector(state => state.list);
    useEffect(() => {
        dispatch(fetchList(props.collection + '/' + localStorage.getItem('token')));
    }, [dispatch, props?.collection]);
    const onSelect = (element) => {
        if (setElementState) {
            setElementState(property, element);
            setListShowed(false);
            return;
        }
        dispatch(setElement({ property, value: element }));
        setListShowed(false);
    }
    const keyDown = e => {
        const index = Array.from(document.getElementsByClassName('select-absolute-list-item')).indexOf(document.activeElement);
        if (e.key === 'ArrowDown') {
            if (!document.activeElement.classList.contains('select-absolute-list-item') && document.getElementsByClassName('select-absolute-list-item')[0] !== undefined) {
                document.getElementsByClassName('select-absolute-list-item')[0].focus();
                return;
            }
            if (index < document.getElementsByClassName('select-absolute-list-item').length - 1) {
                document.getElementsByClassName('select-absolute-list-item')[index + 1].focus();
            }
            return;
        }
        if (e.key === 'ArrowUp') {
            if (document.activeElement.classList.contains('select-absolute-list-item')) {
                if (index > 0) {
                    document.getElementsByClassName('select-absolute-list-item')[index - 1].focus();
                    setElement(items[index - 1]);
                }
            }
            return;
        }
        if (e.key === 'Enter') {
            const listActiveElement = document.getElementsByClassName('select-absolute-list-item')[index];
            if (listActiveElement) {
                listActiveElement.click();
            }
            return;
        }
    }
    const selectInputRef = useRef();
    return (
        <div className='full-width'>
            {
                (action !== 'view' && listShowed === true) &&
                <div className='select-list'>
                    <div className='element-header flex-space-between'>
                        <h3 className="element-title">Выбор - {props.label}</h3>
                        <button className="button cancel" onClick={() => setListShowed(false)}>Отмена</button>
                    </div>
                    {
                        status === 'success' &&
                        items.map((item, index) =>
                            <div key={index} tabIndex={0} className='list-item flex-start align-center select-absolute-list-item' style={{ height: '40px' }} onClick={() => onSelect(item)} onKeyDown={keyDown}>
                                {
                                    item?.icon &&
                                    <img src={item?.icon ? host.url + '/api/' + item.icon : ''} alt="" width={20} height={20} style={{ marginRight: '2px' }} />
                                }

                                <p onClick={() => {
                                    setElementState(item);
                                    setListShowed(false);
                                }} className='select-list-item'>
                                    {item.title}
                                </p>
                            </div>
                        )
                    }
                    {
                        (status === 'success' && items.length === 0) &&
                        <p className="description text-center">Нет элементов</p>
                    }
                    {
                        status === 'loading' &&
                        Array.from({ length: 10 }).map((_, i) =>
                            <div key={i} className='list-item flex-start align-center loading' style={{ height: '40px' }}>
                                <p key={i} className='select-list-item loading'>

                                </p>
                            </div>
                        )
                    }
                    {
                        status === 'error' &&
                        <p className='error' style={{ height: '40px' }}>
                            При загрузки элементов произошла ошибка
                        </p>
                    }
                </div>
            }
            {
                listShowed === false &&
                <div className='text-view flex-column full-width'>
                    <p className="label-view full-width"><span className='required'>{(props.required && action !== 'view') ? '*' : ''}</span>{props.label}</p>
                    {
                        action !== 'view' &&
                        <div className='full-width'>
                            <div className='flex-start background-color-light rounded'>
                                {
                                    data === undefined &&
                                    <div style={{ width: '100%' }}>
                                        <input ref={selectInputRef} type="text" className='no-background-color full-width' onChange={onInputChange} onKeyDown={keyDown} />
                                        {
                                            selectAbsoluteListShowed === true &&
                                            <div className='select-absolute-list'>
                                                {
                                                    status === 'success' &&
                                                    filteredItems.map((item, index) =>
                                                        <div key={index} tabIndex={0} className='list-item flex-start align-center select-absolute-list-item' style={{ height: '40px' }} onClick={() => onSelect(item)} onKeyDown={keyDown}>
                                                            {
                                                                item?.icon &&
                                                                <img src={item?.icon ? host.url + '/api/' + item.icon : ''} alt="" width={20} height={20} style={{ marginRight: '2px' }} />
                                                            }
                                                            <p onClick={() => {
                                                                setElementState(item);
                                                                setListShowed(false);
                                                            }} className='select-list-item'>
                                                                {item.title}
                                                            </p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (status === 'success' && filteredItems.length === 0) &&
                                                    <p className="description text-center">Нет элементов</p>
                                                }
                                                {
                                                    status === 'loading' &&
                                                    Array.from({ length: 10 }).map((_, i) =>
                                                        <div key={i} className='list-item flex-start align-center loading' style={{ height: '40px' }}>
                                                            <p key={i} className='select-list-item loading'></p>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    status === 'error' &&
                                                    <p className='error' style={{ height: '40px' }}>
                                                        При загрузки элементов произошла ошибка
                                                    </p>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    data !== undefined &&
                                    <div tabIndex={0} className='list-item flex-start align-center' style={{ height: '40px' }} onClick={() => setListShowed(true)} onKeyDown={e => { e.key === 'Enter' && e.target.click() }}>
                                        {
                                            data?.icon &&
                                            <img src={data?.icon ? host.url + '/api/' + data.icon : ''} alt="" width={20} height={20} style={{ marginRight: '2px' }} />
                                        }
                                        <p onClick={() => {
                                            setElementState(data);
                                            setListShowed(false);
                                        }} className='select-list-item'>
                                            {data.title}
                                        </p>
                                    </div>
                                }
                                <button className='button no-background-color' onClick={() => setListShowed(true)}>
                                    <img src={addIcon} alt="" width={20} />
                                </button>
                                <button className="button no-background-color flex-center align-center" style={{ display: data ? 'flex' : 'none', borderRadius: '0 5px 5px 0', backgroundColor: 'var(--primary-background-color-light' }} onClick={() => setElementState ? setElementState(property, undefined) : dispatch(setElement({ property, value: undefined }))}><img src={closeIcon} alt="" width={20} /></button>
                            </div>
                        </div>
                    }
                    {
                        action === 'view' &&
                        <div className='flex-start full-width'>
                            <div tabIndex={0} className='flex-start align-center full-width' style={{ height: '40px' }}>
                                {
                                    data?.icon &&
                                    <img src={data?.icon ? host.url + '/api/' + data.icon : ''} alt="" width={20} height={20} style={{ marginRight: '2px' }} />
                                }
                                <p className='select-list-item'>
                                    {data?.title}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    )
}

export default Select