import React, { useEffect, useState } from 'react';
import logo from '../../logo-large.png';
import './Signin.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSignin, formatPhoneNumber, setSignin } from '../User/user.slice';
import loading from '../../images/loading.gif';
import { useNavigate } from 'react-router-dom';
import { fetchPages } from '../Layout/layout.slice';


function Singin() {
    const dispatch = useDispatch();
    const { status } = useSelector(state => state.user.signin);
    const { token, name } = useSelector(state => state.user.user);

    const [phoneNumber, setPhoneNumber] = useState('');
    const [country, setCountry] = useState('+7');
    const [code, setCode] = useState('');
    const [nameValue, setName] = useState('');

    useEffect(() => {
        if (name) {
            setName(name);
        }
    }, [name]);

    const handleInputChange = ({ target: { value } }) => {
        setPhoneNumber(formatPhoneNumber(value, country));
    };

    const handleInputCountryChange = ({ target: { value } }) => {
        value = value.replace(/\D/g, '');
        if (value[0] !== '+') {
            value = '+' + value.substring(0, 3);
        }
        setCountry(value);
    };

    const handleSendCode = () => {
        dispatch(fetchSignin({ phone: phoneNumber.replace(/\D/g, ''), country: country.replace(/\D/g, '') }));
    };

    const handleCheckCode = () => {
        dispatch(fetchSignin({ phone: phoneNumber.replace(/\D/g, ''), country: country.replace(/\D/g, ''), code }));
    };

    const navigate = useNavigate();
    const handleSaveName = () => {
        dispatch(fetchSignin({ name: nameValue, phone: phoneNumber.replace(/\D/g, ''), country: country.replace(/\D/g, '') }));
        dispatch(fetchPages(token + '?justPages=true'));
        localStorage.setItem('token', token);
        navigate('/');
    };

    return (
        <div className="signin-container signin-main-container" style={{ height: '100%', paddingBottom: '250px' }}>
            <div className='flex-center flex-column align-center' style={{ justifySelf: 'flex-start' }}>
                <img src={logo} alt="" />
                <p className='signin-label' style={{ marginBottom: '50px' }}>Вход на аккаунт</p>
            </div>
            <div>
                {
                    status === null &&
                    <div className="signin-container">
                        <p className='signin-label'>Введите номер телефона, на который зарегистрирован аккаунт WhatsApp</p>
                        <div className='signin-input-container'>
                            <input className='signin-input-country' type="text" value={country} onChange={evt => handleInputCountryChange(evt)} />
                            <input className='signin-input signin-input-phone' type="text" placeholder="Номер телефона" value={phoneNumber ?? ''} onChange={evt => handleInputChange(evt)} />
                        </div>
                        <button className='button signin-button' onClick={handleSendCode} disabled={phoneNumber?.length < 6 | country?.length < 2}>Далее</button>
                    </div>
                }

                {
                    status === 'code sent' &&
                    <div className="signin-container">
                        <p className='signin-label'>Введите код из WhatsApp, отправленный на номер {country} {phoneNumber}</p>
                        <div>
                            <input className='signin-input-code' type="text" placeholder="Код" value={code} onChange={evt => setCode(evt.target.value)} />
                        </div>
                        <button className='button signin-button' disabled={code.length < 4} onClick={handleCheckCode}>Войти</button>
                    </div>
                }

                {
                    status === 'wrong code' &&
                    <div className="signin-container">
                        <p className='signin-label'>Неверный код, пожалуйста повторите</p>
                        <button className='button' onClick={() => { dispatch(setSignin({ status: 'code sent' })) }}>Повторить</button>
                    </div>
                }

                {
                    status === 'code expired' &&
                    <div className="signin-container">
                        <p className='signin-label'>Срок кода истек, пожалуйста повторите</p>
                        <button className='button' onClick={() => { dispatch(setSignin({ status: null })) }}>Повторить</button>
                    </div>
                }

                {
                    status === 'code already sent' &&
                    <div className="signin-container">
                        <p className='signin-label'>Коде отправлен недавно, пожлауйста повторите через несколько минут</p>
                        <button className='button' onClick={() => { dispatch(setSignin({ status: null })) }}>Повторить</button>
                    </div>
                }

                {
                    status === 'error' &&
                    <div className="signin-container">
                        <p className='signin-label'>Произошла ошибка</p>
                        <button className='button' onClick={() => { dispatch(setSignin({ status: null })) }}>Повторить</button>
                    </div>
                }

                {
                    status === 'loading' &&
                    <div className="signin-container">
                        <img src={loading} alt="" style={{ width: '30px', height: '30px' }} />
                    </div>
                }

                {
                    status === 'success' &&
                    <div className="signin-container">
                        <p className='signin-label'>Ваше имя</p>
                        <input className='signin-input-name' type="text" placeholder="Введите ваше имя" value={nameValue} onChange={evt => setName(evt.target.value)} />
                        <button className='button signin-button' disabled={nameValue?.length < 1} onClick={handleSaveName}>Сохранить</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default Singin