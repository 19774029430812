import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);


if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      await navigator.serviceWorker.register('/service-worker.js');
    } catch {

    }
  });
}

// setTimeout(async () => {
//   const requestNotificationPermission = await Notification.requestPermission();
//   if (requestNotificationPermission === 'granted') {
//     console.log('Notification permission granted');
//   } else {
//     console.log('Notification permission denied');
//   }
// }, 1000);

// function sendNotification() {
//   if (Notification.permission === 'granted') {
//       new Notification('Hello!', {
//           body: 'This is a notification from your React app.',
//           icon: 'path/to/icon.png' // Optional icon
//       });
//   }
// }

// sendNotification();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
