import React, { useState } from 'react';
import logo from '../../logo-small.png';
import './Menu.css';
import menu from '../../images/menu.png';
import { NavLink } from 'react-router-dom';
import { host } from '../../App';
import ImageComponent from '../ImageComponent/ImageComponent';
function Menu({ isLeftMenu, items }) {
    const [showed, setShow] = useState('LeftMenu-container');

    const menuButtons = items.map(page => {
        return {
            id: page.collection,
            img: {
                src: host.url + '/api/' + page.icon,
                alt: page.collection,
            },
            title: page.title,
            className: 'menuButton',
            type: (page.collection === 'user' || page.collection === 'shop') ? 'bottomButton' : 'topButton',
        }
    });
    const showHide = () => {
        if (showed === 'LeftMenu-container' | showed === 'LeftMenu-container hide') {
            setShow('LeftMenu-container show');
            document.getElementsByClassName('Layout-container')[0].style.gridTemplateColumns = '200px 1fr';
            return;
        }

        setShow('LeftMenu-container hide');
        document.getElementsByClassName('Layout-container')[0].style.gridTemplateColumns = '50px 1fr';
    };

    const leftMenu = () => {
        return (
            <div className={showed}>
                <div className='iconContainer'>
                    <img src={logo} alt="zardolu" id='leftMenuIcon' width={30} />
                    <span className='buttonTitle'>Зардолу</span>
                </div>

                <div className='topButtons'>
                    <button className='menuButton openCloseButton' onClick={showHide}>
                        <img src={menu} alt="menu" width={30} />
                    </button>
                    {
                        menuButtons.map((button, index) => {
                            if (button.type === 'bottomButton') {
                                return null;
                            }
                            if (index > 10) {
                                return null;
                            }
                            return (
                                <NavLink onClick={() => document.title = button.title} to={'/' + button.img.alt} key={button.img.alt} id={button.img.alt} className={button.className}>
                                    <div className='menu-item-icon-container'>
                                        <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                        <ImageComponent src={button.img.src} alt={button.img.alt} width={30} />
                                    </div>
                                    <span className='buttonTitle'>{button.title}</span>
                                </NavLink>);
                        })
                    }
                </div>
                <div className='bottomButtons'>
                    {
                        menuButtons.reverse().map(button => {
                            if (button.type === 'topButton') {
                                return null;
                            }
                            return (
                                <NavLink onClick={() => document.title = button.title} to={'/' + button.img.alt} key={button.img.alt} id={button.img.alt} className={button.className}>
                                    <div className='menu-item-icon-container'>
                                        <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                        <ImageComponent src={button.img.src} alt={button.img.alt} width={30} />
                                    </div>
                                    <span className='buttonTitle'>{button.title}</span>
                                </NavLink>);
                        })
                    }
                </div>
            </div>
        )
    };

    const bottomMenu = () => {
        const userButton = menuButtons.find(button => button.id === 'user');
        return (
            <div className='bottomMenu'>
                {
                    menuButtons.map((button, index) => {
                        if (index > 3 || button.type === 'bottomButton') {
                            return null;
                        }
                        return (
                            <NavLink onClick={() => document.title = button.title} to={'/' + button.img.alt} key={button.img.alt} id={button.img.alt} className={button.className}>
                                <div className='menu-item-icon-container'>
                                    <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                    <ImageComponent src={button.img.src} alt={button.img.alt} width={30} />
                                </div>
                                <span className='buttonTitle'>{button.title}</span>
                            </NavLink>);
                    })
                }
                {
                    menuButtons.map((button, index) => {
                        if (button.type === 'topButton' || button.id === 'user') {
                            return null;
                        }
                        return (
                            <NavLink onClick={() => document.title = button.title} to={'/' + button.img.alt} key={button.img.alt} id={button.img.alt} className={button.className}>
                                <div className='menu-item-icon-container'>
                                    <span className={button?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{button?.badge}</span>
                                    <ImageComponent src={button.img.src} alt={button.img.alt} width={30} />
                                </div>
                                <span className='buttonTitle'>{button.title}</span>
                            </NavLink>);
                    })
                }
                {
                    <NavLink onClick={() => document.title = userButton.title} to={'/' + userButton.img.alt} key={userButton.img.alt} id={userButton.img.alt} className={userButton.className}>
                        <div className='menu-item-icon-container'>
                            <span className={userButton?.badge > 0 ? 'buttonBadge activeBadge' : 'buttonBadge'}>{userButton?.badge}</span>
                            <ImageComponent src={userButton.img.src} alt={userButton.img.alt} width={30} />
                        </div>
                        <span className='buttonTitle'>{userButton.title}</span>
                    </NavLink>
                }
            </div>
        )
    };

    return (
        <>
            {items.length > 1 ? isLeftMenu ? leftMenu() : bottomMenu() : null}
        </>
    )
}

export default Menu